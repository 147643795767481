<template>
  <div>
    <h2 class="col-lg-12 text-center mb-3">{{ ('Add Schedule') }}</h2>
    <FormSchedule @submit-requested="submitRequested"></FormSchedule>
  </div>
</template>
 
<script>
import { mapActions } from 'vuex'
import FormSchedule from '../forms/FormSchedule.vue'

  export default {
  components: { FormSchedule },
  methods: {
    ...mapActions('schedule', ['addSchedule']),
    submitRequested (scheduleInfo) {
      this.addSchedule(scheduleInfo);
    },
  }

  }
</script> 
 
<style scoped>
thead > tr > th
{
  text-align: unset !important;
}
</style> 